<template>
  <!-- 渠道商 -->
  <div class="container">
    <a-page-header :title="title" />
    <a-tabs :default-active-key="tabIndex" :animated="false" @change="callback">
      <a-tab-pane v-for="item in tabs" :key="item.key" :tab="item.name">
        <div class="main-content" v-if="tabIndex == 0">
          <div class="main-content-header">
            <div class="item">
              <div class="title">渠道商列表</div>
            </div>
            <div class="item">
              <a-button
                v-permission="'add'"
                type="primary"
                @click="$jump({ path: '/channelBusinessAdd' })"
              >
                新增渠道商
              </a-button>
            </div>
          </div>
          <my-table
            :columns="columns"
            :data-source="item.dataSource"
            :pagination="false"
            :loading="loading"
            :scroll="{ x: 1150, y: true }"
          >
            <template slot="rate" slot-scope="record">
              {{ record.rate }}%
            </template>
            <template slot="num" slot-scope="record">
              {{ record.num }}个
            </template>
            <template slot="qrcode" slot-scope="record">
              <a-button
                type="link"
                @click="onPreviewQRcode(record.wechatAppletsQcode)"
              >
                预览图片
              </a-button>
            </template>
            <template slot="action" slot-scope="record">
              <a-button type="link" @click="onChange(record)"> 编辑 </a-button>
              <a-button type="link" v-permission="'info'">
                <router-link
                  :to="{
                    path: '/channelBusinessInfo',
                    query: { id: record.id }
                  }"
                >
                  详情
                </router-link>
              </a-button>
              <a-button type="link">
                <router-link
                  :to="{
                    path: '/channelTerminal',
                    query: { id: record.id, name: record.realName }
                  }"
                >
                  渠道终端
                </router-link>
              </a-button>
              <a-button type="link" @click="onDel(record.id)"> 删除 </a-button>
            </template>
          </my-table>
        </div>

        <a-modal
          title="分享二维码"
          :visible="modalQrcodeVisible"
          @cancel="onPreviewQRcodeClose"
          :footer="false"
        >
          <img class="qrcode" :src="qrcode" />
        </a-modal>

        <a-modal
          title="编辑"
          :visible="modalChangeVisible"
          @cancel="onChangeClose"
          @ok="onChangeSubmit"
        >
          <a-form-model
            ref="ruleForm"
            :model="form"
            :rules="rules"
            :label-col="{ span: 7 }"
            :wrapper-col="{ span: 16 }"
          >
            <a-form-model-item
              ref="realName"
              label="渠道商名称"
              prop="realName"
            >
              <a-input
                v-model="form.realName"
                placeholder="请输入渠道商名称"
                @blur="
                  () => {
                    $refs.realName.onFieldBlur()
                  }
                "
              />
            </a-form-model-item>
            <a-form-model-item ref="rate" label="渠道商业绩比例" prop="rate">
              <a-input
                v-model="form.rate"
                placeholder="请输入渠道商业绩比例"
                @blur="
                  () => {
                    $refs.rate.onFieldBlur()
                  }
                "
              />
            </a-form-model-item>
          </a-form-model>
        </a-modal>
        <!--  -->
       <div class="main-content" v-if="tabIndex == 1">
          <div class="main-content-header">
          <div class="item">
            <div class="title">
              <span class="title-1">渠道终端申请列表</span>
              <a-icon type="info-circle" />
              <!-- <span class="sub-title">仅用于家电、商品等规格显示</span> -->
            </div>
          </div>
        </div>

        <my-table
          :columns="columnsList"
          :data-source="item.dataSource"
          :pagination="false"
          :loading="loading2"
           :scroll="{ x: 1150, y: true }"
          :row-key="(record) => record.id"
        >
          <template slot="spreadName" slot-scope="record">
           <span v-if="record.spreadName"> {{ record.spreadName.nickname }}</span>
          </template>
          <template slot="action" slot-scope="record">
            <div class="flex" v-if="record.status == 0">
              <a-button type="link" @click="agree(record, 1)"> 同意 </a-button>
              <a-button type="link" @click="agree(record, 2)">
                不同意
              </a-button>
            </div>
              <a-button type="link" v-if="record.status == 1">已同意</a-button>
              <a-button type="link" v-if="record.status == 2">已拒绝</a-button>
          </template>
        </my-table>
       </div>
      </a-tab-pane>
    </a-tabs>
    <!-- 同意弹窗 -->
    <a-modal
      :title="title"
      :width="500"
      :visible="agreeShow"
      :maskClosable="false"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      @cancel="handleModalCancel"
    >
      <a-form-model
        ref="ruleForm"
        :model="formTion"
        :rules="rulesTion"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 20 }"
      >
        <a-form-model-item ref="name" :label="agreeName" prop="proportion">
          <a-input
            placeholder="请输入1到100的比例"
            v-model="formTion.proportion"
            v-if="agreeNum == 1"
          />
          <a-input
            placeholder="请输入理由"
            v-model="formTion.proportion"
            v-else
          />
        </a-form-model-item>
      </a-form-model>
      <template slot="footer">
        <a-button @click="handleModalCancel2"> 取消 </a-button>
        <a-button type="primary" @click="handleModalConfirm2"> 确定 </a-button>
      </template>
    </a-modal>
    <!-- 不同意弹窗 -->
  </div>
</template>

<script>
import NP from 'number-precision'
export default {
  data() {
    return {
      dataSource1: [],
      agreeShow: false,
      confirmLoading: false,
      tabIndex: 0,
      tabs: [
        {
          key: 0,
          name: '渠道商',
          type: 'serving',
          dataSource: [],
          loading: true,
          pagination: {
            current: 1,
            total: 0
          }
        },
        {
          key: 1,
          name: '渠道终端申请列表',
          type: 'card',
          dataSource: [],
          loading: true,
          pagination: {
            current: 1,
            total: 0
          }
        }
      ],
      columns: [
        {
          title: '序号',
          width: 70,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '渠道商名称',
          width: 200,
          dataIndex: 'realName'
        },
        {
          title: '渠道商业绩比例',
          width: 150,
          scopedSlots: { customRender: 'rate' }
        },
        {
          title: '渠道开发的终端数',
          width: 150,
          scopedSlots: { customRender: 'num' }
        },
        {
          title: '分享二维码',
          scopedSlots: { customRender: 'qrcode' }
        },
        {
          title: '操作',
          width: 300,
          fixed: 'right',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ],
      loading: true,
      modalQrcodeVisible: false,
      qrcode: '',
      modalChangeVisible: false,
      form: {
        realName: '',
        rate: ''
      },
      rules: {
        realName: [
          { required: true, message: '请输入渠道商名称', trigger: 'blur' }
        ],
        rate: [
          { required: true, message: '请输入渠道商业绩比例', trigger: 'blur' }
        ],
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }]
      },
      columnsList: [
        {
          title: '序号',
          width: 60,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '姓名',
          dataIndex: 'name'
        },
        {
          title: '申请账号类型',
          dataIndex: 'userType'
        },
        {
          title: '申请人姓名',
          scopedSlots: { customRender: 'spreadName' }
        },
        {
          title: '申请理由',
          dataIndex: 'desc'
        },
        {
          title: '申请人员',
          dataIndex: 'username'
        },
        {
          title: '申请时间',
          dataIndex: 'createAt'
        },
        {
          title: '分配比例',
          dataIndex: 'rate'
        },
        {
          title: '平台拒绝理由',
          dataIndex: 'rejectDesc'
        },
        {
          title: '操作',
          fixed: 'right',
          width: 200,
          scopedSlots: { customRender: 'action' }
        }
      ],
      loading2: true,
      visible: false,
      drawerTitle: '',
      dataSource2: '',
      formData: {
        name: ''
      },
      //同意比例
      formTion: {
        proportion: ''
      },
      rulesTion: {
        proportion: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      agreeId: '',
      agreeNum: '',
      agreeName: ''
    }
  },
  created() {
    this.title = this.$getPageTitle()
    this.getChannelBusiness()
  },
  methods: {
    callback(key) {
      // tab切换
      this.tabIndex = key
      if (key === 0) {
        this.getChannelBusiness()
      } else {
        this.businessApplyList()
      }
    },
    getChannelBusiness() {
      // 获取渠道商列表
      this.$axios.getChannelBusiness().then((res) => {
        const l = res.data.data
        l.forEach((e) => {
          if (e.rate) {
            e.rate = NP.times(e.rate, 100)
          }
        })
        // this.dataSource1 = l
         this.tabs[0].dataSource = l
        this.loading = false
      })
    },
    // 渠道商申请列表
    businessApplyList() {
      this.$axios.businessApplyList().then((res) => {
        console.log('res', res)
        // this.dataSource2 = res.data.data
         this.tabs[1].dataSource = res.data.data
        this.loading2 = false
      })
    },

    onPreviewQRcode(e) {
      // 预览二维码
      this.qrcode = e
      this.modalQrcodeVisible = true
    },
    onPreviewQRcodeClose() {
      // 关闭预览二维码
      this.qrcode = ''
      this.modalQrcodeVisible = false
    },
    onChange(e) {
      this.form = {
        id: e.id,
        realName: e.realName,
        rate: e.rate
      }
      this.modalChangeVisible = true
    },
    onChangeClose() {
      // 取消编辑
      // this.$refs.ruleForm.resetFields()
      this.modalChangeVisible = false
    },
    onChangeSubmit() {
      // 新增渠道商
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.addLoading = true
          const data = {
            id: this.form.id,
            realName: this.form.realName,
            rate: NP.divide(this.form.rate, 100)
          }
          this.$axios.changeChannelBusiness(data).then(() => {
            this.$message.success('操作成功')
            this.onChangeClose()
            this.getChannelBusiness()
          })
        }
      })
    },
    onDel(id) {
      this.$confirm({
        title: '确定删除吗？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          const data = {
            id: id,
            configType: 1
          }
          this.$axios.delChannel(data).then(() => {
            this.$message.success('操作成功')
            this.getChannelBusiness()
          })
        }
      })
    },
    //同意不同意弹窗
    agree(e, num) {
      this.agreeShow = true
      this.agreeId = e.id
      this.agreeNum = num
      if (num == 1) {
        this.agreeName = '比例'
      } else {
        this.agreeName = '理由'
      }
    },
    handleModalConfirm2() {
      const data = {
        id: this.agreeId,
        status: this.agreeNum
      }
      if (this.agreeNum == 1) {
        if (!/(^[1-9]\d*$)/.test(this.formTion.proportion)) {
          this.$message.error('请输入正整数0也不能输入')
          return false
        }
        if (this.formTion.proportion <= 100) {
          data.rate = this.formTion.proportion / 100
        } else {
          this.$message.error('比例不能超出100')
          return
        }
      } else {
        data.rejectDesc = this.formTion.proportion
      }
      this.$axios.acceptApply(data).then(() => {
        this.$message.success('操作成功')
        this.agreeShow = false
        this.businessApplyList()
      })
    },
    handleModalCancel2() {
      this.agreeShow = false
      this.formTion.proportion = ''
    },
    handleModalCancel() {
      // 取消修改上门时间
      this.agreeShow = false
      this.formTion.proportion = ''
    }
  }
}
</script>

<style scoped>
.qrcode {
  display: block;
  margin: 0 auto;
}
.flex {
  display: flex;
}
</style>
